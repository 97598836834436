
// @ts-nocheck


export const localeCodes =  [
  "ru"
]

export const localeLoaders = {
  "ru": []
}

export const vueI18nConfigs = [
  () => import("../i18n/i18n.config.ts?hash=5b32d2e0&config=1" /* webpackChunkName: "i18n_config_5b32d2e0" */)
]

export const nuxtI18nOptions = {
  "restructureDir": "i18n",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": false,
    "generatedLocaleFilePathFormat": "absolute",
    "alternateLinkCanonicalQueries": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "ru",
      "name": "Russian"
    }
  ],
  "defaultLocale": "ru",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "donut-locale",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "messages": {
    "ru": {
      "common": {
        "becomeClient": "Стать клиентом Donut",
        "moreDetails": "Подробнее",
        "tryFree": "Попробовать бесплатно"
      },
      "contactForm": {
        "email": {
          "placeholder": "Электронная почта"
        },
        "name": {
          "placeholder": "Ваше имя"
        },
        "phone": {
          "placeholder": "Телефон"
        },
        "question": {
          "placeholder": "Ваш вопрос или предложение"
        },
        "submit": "Отправить"
      },
      "knowledgeBaseBlock": {
        "button": "Перейти в базу знаний",
        "description": "Всё, что нужно знать о&nbsp;нашем сервисе",
        "title": "База знаний Donut"
      },
      "pages": {
        "contacts": {
          "email": {
            "label": "Почта"
          },
          "headerTitle": "Все наши контакты&nbsp;здесь",
          "orderForm": {
            "description": "Напишите нам. Менеджер скоро с&nbsp;вами свяжется",
            "title": "Есть вопросы или&nbsp;предложения?"
          },
          "seo": {
            "title": "Контакты"
          },
          "social": {
            "label": "Социальные сети"
          },
          "telegram": {
            "label": "Telegram"
          }
        },
        "index": {
          "approach": {
            "subtitle": "Система управления со всем самым нужным перед глазами",
            "title": "Продуманный <br /> и понятный подход"
          },
          "bonuses": {
            "items": {
              "earnings": {
                "description": "Создайте свой сайт-приложение для быстрой продажи товаров и&nbsp;простой онлайн-записи на&nbsp;услуги",
                "title": "Зарабатывайте больше"
              },
              "efficiency": {
                "description": "Управляйте продажами, записями и&nbsp;чатами через CRM-систему даже со&nbsp;смартфона",
                "title": "Повышайте эффективность"
              },
              "visibility": {
                "description": "Сайт будет доступен как встроенное приложение в&nbsp;мессенджерах или&nbsp;по&nbsp;прямой&nbsp;ссылке",
                "title": "Будьте заметнее"
              }
            }
          },
          "businesses": {
            "beautySalon": "Салон красоты",
            "billiards": "Бильярдная",
            "carWash": "Автомойка",
            "clothingStore": "Магазин одежды",
            "fitness": "Фитнес",
            "lawyer": "Адвокат",
            "notary": "Нотариус",
            "photoStudio": "Фотостудия",
            "restaurant": "Ресторан",
            "title": "Используйте в&nbsp;любом&nbsp;бизнесе",
            "yourBusiness": "Ваш бизнес"
          },
          "cards": {
            "first": {
              "chats": {
                "description": "Общайтесь с&nbsp;клиентами из&nbsp;разных мессенджеров в&nbsp;CRM, а&nbsp;бот поможет ответить на&nbsp;самые популярные вопросы за&nbsp;менеджера",
                "title": "Чаты с клиентами"
              },
              "crm": {
                "description": "Пользуйтесь понятной и&nbsp;функциональной системой управления вашими заказами, записями и&nbsp;заявками",
                "title": "Эффективная CRM"
              },
              "onlineRecord": {
                "description": "Управляйте индивидуальными и&nbsp;групповыми записями всех филиалов через журнал, а&nbsp;рабочее расписание сотрудников повысит эффективность и&nbsp;прозрачность работы",
                "title": "Онлайн-запись"
              },
              "services": {
                "description": "Предлагайте клиентам любые услуги с&nbsp;быстрой отправкой заявки, а&nbsp;при необходимости включите для них расписание и&nbsp;онлайн-запись",
                "title": "Услуги с заявками"
              },
              "shop": {
                "description": "Продавайте товары в&nbsp;удобном каталоге с&nbsp;гибкими настройками, а&nbsp;наша CRM не&nbsp;даст потеряться ни&nbsp;одному заказу и&nbsp;посчитает все остатки на&nbsp;складе",
                "title": "Интернет-магазин"
              }
            },
            "second": {
              "bot": {
                "description": "Лёгкое подключение бота с&nbsp;настройкой автоматических ответов клиенту, уведомлений и&nbsp;команд",
                "title": "Конструктор бота"
              },
              "cabinet": {
                "description": "История всех заказов, записей и&nbsp;заявок в&nbsp;одном удобном профиле с&nbsp;отслеживанием статусов и&nbsp;персональными скидками",
                "title": "Личный кабинет клиента"
              },
              "website": {
                "description": "Мы&nbsp;продумали почти все&nbsp;&mdash; останется только настроить фирменный цвет и&nbsp;добавить нужную информацию о&nbsp;компании",
                "title": "Конструктор сайта"
              }
            }
          },
          "features": {
            "subtitle": "Если не&nbsp;найдете нужный функционал&nbsp;&mdash; мы&nbsp;его добавим!",
            "title": "Один Donut, <br /> множество возможностей"
          },
          "header": {
            "subtitle": "Сервис для автоматизации бизнеса",
            "title": "Онлайн запись, товары и&nbsp;услуги через мессенджер"
          },
          "integration": {
            "subtitle": "Поможем с&nbsp;интеграцией на&nbsp;всех этапах",
            "title": "Внедрите Donut<br />в&nbsp;бизнес всего за&nbsp;4&nbsp;шага"
          },
          "quickBuy": {
            "platforms": "Поддерживаемые платформы",
            "subtitle": "Система управления со всем самым нужным перед глазами",
            "title": "Быстрая покупка <br /> или запись через мессенджер"
          },
          "sectionCards": {
            "barbershop": {
              "description": "Запись на&nbsp;одиночные или групповые услуги с&nbsp;учётом рабочего графика ваших сотрудников",
              "title": "Барбершоп"
            },
            "clothing": {
              "description": "Продажа в&nbsp;каталоге с&nbsp;настраиваемыми фильтрами категорий и&nbsp;вариантами товаров по&nbsp;нужным параметрам",
              "title": "Магазин одежды"
            },
            "legal": {
              "description": "Получение заявок на&nbsp;услуги с&nbsp;возможностью в&nbsp;любой момент переключить их&nbsp;на&nbsp;онлайн-запись",
              "title": "Юридическая помощь"
            }
          },
          "steps": {
            "data": {
              "description": "Поможем с&nbsp;заполнением каталога товаров и&nbsp;услуг, онлайн-расписанием сотрудников",
              "title": "Расскажем как перенести данные"
            },
            "instructions": {
              "description": "Избавим ваших сотрудников от&nbsp;долгого изучения работы системы",
              "title": "Выдадим&nbsp;инструкции для сотрудников"
            },
            "needs": {
              "description": "Расскажем про весь функционал сервиса, который может быть вам полезен",
              "title": "Узнаем ваши потребности"
            },
            "setup": {
              "description": "Покажем как всё работает и&nbsp;как быстро настроить CRM под ваши потребности",
              "title": "Поможем настроить сервис под вас"
            },
            "step_number": "Шаг {number}"
          }
        },
        "questions": {
          "contact": {
            "description": "Напишите нам. Менеджер скоро с&nbsp;вами свяжется",
            "title": "Остались вопросы или&nbsp;нужна помощь?"
          },
          "headerTitle": "Поможем разобраться",
          "list": {
            "1": {
              "answer": "Donut - это инновационная платформа для создания бизнес-приложений в мессенджерах. Она объединяет в себе:<br><br>\n  &nbsp;&nbsp;•&nbsp;&nbsp;Конструктор сайтов, адаптированных под популярные мессенджеры<br>\n  &nbsp;&nbsp;•&nbsp;&nbsp;Систему онлайн-записи и каталогизации товаров/услуг<br>\n  &nbsp;&nbsp;•&nbsp;&nbsp;Автоматизированного бота для общения с клиентами<br>\n  &nbsp;&nbsp;•&nbsp;&nbsp;Встроенную CRM-систему для эффективного управления бизнесом<br><br>\nВсё это работает как единая экосистема, позволяя вашему бизнесу быть там, где находятся ваши клиенты - в мессенджерах.",
              "title": "Что такое Donut?"
            },
            "2": {
              "answer": "Donut создан для бизнеса любого масштаба, который хочет эффективно взаимодействовать с клиентами в мессенджерах:<br><br>\n            &nbsp;&nbsp;•&nbsp;&nbsp;Сфера красоты и здоровья: салоны красоты, спа-центры, фитнес-клубы<br>\n              &nbsp;&nbsp;•&nbsp;&nbsp;Розничная торговля: интернет-магазины, шоурумы, бутики<br>\n              &nbsp;&nbsp;•&nbsp;&nbsp;Сфера услуг: ремонт, клининг, доставка<br>\n              &nbsp;&nbsp;•&nbsp;&nbsp;Образование: школы, курсы, репетиторы<br>\n              &nbsp;&nbsp;•&nbsp;&nbsp;Медицина: частные клиники, стоматологии, консультации<br>\n              &nbsp;&nbsp;•&nbsp;&nbsp;Развлечения: рестораны, кафе, event-агентства<br><br>\n            Особенно платформа будет полезна тем, кто хочет автоматизировать работу с клиентами и добавить бизнес-процессы в популярные мессенджеры.",
              "title": "Кому подойдет использование Donut?"
            },
            "3": {
              "answer": "Donut — это больше, чем просто сайт в мессенджере. Это полноценная CRM-система для управления вашим бизнесом, интегрированная с популярными мессенджерами, где сегодня находится большинство клиентов. В отличие от обычных сайтов, каждый посетитель автоматически становится потенциальным клиентом — его контакты сохраняются, и вы можете продолжить общение даже после его ухода.<br><br>\n            Встроенный бот берет на себя рутинные задачи: отвечает на типовые вопросы, принимает заказы и записи, отправляет уведомления. Это значительно снижает нагрузку на ваших менеджеров и обеспечивает мгновенную реакцию на запросы клиентов в любое время суток. При этом вам не нужно разрабатывать и поддерживать отдельные приложения для App Store или Google Play — всё работает через привычные мессенджеры на любых устройствах.",
              "title": "Почему вам стоит использовать Donut?"
            },
            "4": {
              "answer": "Главное преимущество для ваших клиентов — максимальное удобство. Им не нужно устанавливать новые приложения или запоминать адреса сайтов — всё происходит в привычном мессенджере. Здесь они могут посмотреть ваши товары и услуги, сделать заказ или записаться на прием, получить консультацию и следить за статусом своего заказа.<br><br>\n            Вся история общения, заказов и записей автоматически сохраняется в чате и доступна с любого устройства. А благодаря мгновенным уведомлениям в мессенджере, клиенты всегда в курсе важных событий: подтверждения записи, напоминания о визите или информации о специальных предложениях.",
              "title": "Какие выгоды получат ваши клиенты?"
            },
            "5": {
              "answer": "Donut уникален тем, что объединяет все необходимые инструменты для ведения бизнеса в мессенджерах в одном сервисе. В отличие от других платформ, которые предлагают либо создание ботов, либо CRM-систему, либо конструктор сайтов — Donut предоставляет полноценную экосистему. Здесь есть всё: от создания сайта в мессенджерах до управления клиентской базой, от автоматизации общения до аналитики продаж. При этом платформа остается простой в использовании и не требует технических навыков.",
              "title": "Чем Donut отличается от других сервисов?"
            },
            "6": {
              "answer": "Запустить свой проект в Donut можно за несколько часов, даже если у вас нет технических навыков. Мы создали интуитивно понятный интерфейс, где каждый шаг создания проекта логичен и прост.<br><br>\n            Чтобы вы могли быстро освоиться, мы разработали пошаговые обучающие подсказки прямо в интерфейсе и создали подробную базу знаний. А если возникнут вопросы — наша служба поддержки всегда готова помочь.",
              "title": "Сколько времени займет создания сайта и бота?"
            },
            "7": {
              "answer": "Мы предоставляем бесплатный 14-дневный период, чтобы вы могли полноценно протестировать все возможности сервиса. За это время вы сможете создать свой проект, оценить функционал и выбрать подходящий тариф для ваших задач. Актуальные цены и описание тарифов вы можете посмотреть на странице <a class=\"ui-link-secondary\" href='/client/about/rates'>тарифов</a>.",
              "title": "Сколько стоит использование Donut?"
            },
            "8": {
              "answer": "Связаться с командой поддержки можно через электронную почту, чат на сайте, а также через мессенджеры, если это предусмотрено вашим тарифным планом. Для более оперативного решения вопросов рекомендуется использовать приоритетную поддержку или персонального менеджера.",
              "title": "Как связаться с командой поддержки?"
            }
          },
          "seo": {
            "title": "Вопросы и ответы"
          }
        },
        "rates": {
          "comparison": {
            "title": "Сравните функции&nbsp;тарифов"
          },
          "contactForm": {
            "description": "Напишите нам для подбора персональных условий",
            "title": "Не нашли подходящий&nbsp;тариф?"
          },
          "faq": {
            "title": "Популярные вопросы"
          },
          "headerTitle": "Тарифный план для каждого",
          "seo": {
            "title": "Тарифы"
          }
        }
      },
      "rates": {
        "annualPayment": "при оплате за год",
        "comparison": {
          "activeChats": {
            "description": "Без учёта архивных",
            "title": "Активных чатов"
          },
          "cost": "Стоимость",
          "donutAds": "Реклама Donut",
          "employees": "Сотрудников",
          "features": "Функции",
          "monthlyPayment": "Оплата за месяц",
          "productsAndServices": "Товаров и услуг",
          "storage": {
            "description": "Для файлов из&nbsp;чата и&nbsp;другого",
            "title": "Хранилище, Гб"
          },
          "support": "Поддержка",
          "yearlyPayment": "Оплата за год"
        },
        "customParameters": "в зависимости от выбранных параметров",
        "or": "или",
        "plans": {
          "base": {
            "advantages": {
              "activeChats": "100 активных чатов",
              "advertising": "Реклама сервиса Donut",
              "employee": "1 сотрудник",
              "products": "30 товаров и 30 услуг",
              "storage": "25 Гб хранилища",
              "support": "Базовая поддержка"
            },
            "button": "Попробовать бесплатно",
            "description": "Всё необходимое для успешной работы профессионала",
            "donutAds": "Есть",
            "name": "Base",
            "price": {
              "annual": "$12 / мес",
              "monthly": "$15 / мес"
            },
            "support": "Базовая"
          },
          "enterprise": {
            "advantages": {
              "advertising": "Нет рекламы сервиса Donut",
              "development": "Услуги по разработке",
              "integrations": "Дополнительные интеграции",
              "limits": "Индивидуальные лимиты",
              "manager": "Персональный менеджер"
            },
            "button": "Оставить заявку",
            "description": "Индивидуальные решения, поддержка и интеграции",
            "name": "Enterprise",
            "price": {
              "custom": "Договорная стоимость"
            },
            "requestButton": "Оставить заявку"
          },
          "large": {
            "advantages": {
              "advertising": "Нет рекламы сервиса Donut",
              "chats": "1 000 активных чатов",
              "employees": "15 сотрудников",
              "products": "2 000 товаров и 2 000 услуг",
              "storage": "300 Гб хранилища",
              "support": "Приоритетная поддержка"
            },
            "button": "Попробовать бесплатно",
            "description": "Для крупных компаний с большими объёмами товаров или услуг",
            "donutAds": "Нет",
            "name": "Large",
            "price": {
              "annual": "$60 / мес",
              "monthly": "$75 / мес"
            },
            "support": "Приоритетная"
          },
          "medium": {
            "advantages": {
              "advertising": "Нет рекламы сервиса Donut",
              "chats": "600 активных чатов",
              "employees": "10 сотрудников",
              "products": "1 000 товаров и 1 000 услуг",
              "storage": "150 Гб хранилища",
              "support": "Приоритетная поддержка"
            },
            "button": "Попробовать бесплатно",
            "description": "Оптимальный выбор для среднего бизнеса с расширенными лимитами",
            "donutAds": "Нет",
            "name": "Medium",
            "price": {
              "annual": "$40 / мес",
              "monthly": "$50 / мес"
            },
            "support": "Приоритетная"
          },
          "small": {
            "advantages": {
              "advertising": "Нет рекламы сервиса Donut",
              "chats": "300 активных чатов",
              "employees": "5 сотрудников",
              "products": "100 товаров и 100 услуг",
              "storage": "50 Гб хранилища",
              "support": "Приоритетная поддержка"
            },
            "button": "Попробовать бесплатно",
            "description": "Идеально для малого бизнеса с приоритетной поддержкой",
            "donutAds": "Нет",
            "name": "Small",
            "price": {
              "annual": "$20 / мес",
              "monthly": "$25 / мес"
            },
            "support": "Приоритетная"
          },
          "ultimate": {
            "advantages": {
              "advertising": "Нет рекламы сервиса Donut",
              "chats": "1 500 активных чатов",
              "employees": "20 сотрудников",
              "manager": "Персональный менеджер",
              "products": "4 000 товаров и 4 000 услуг",
              "storage": "500 Гб хранилища"
            },
            "button": "Попробовать бесплатно",
            "description": "Максимальные возможности и персональный менеджер",
            "donutAds": "Нет",
            "name": "Ultimate",
            "price": {
              "annual": "$80 / мес",
              "monthly": "$100 / мес"
            },
            "support": "Персональный менеджер"
          }
        },
        "questions": {
          "activeChat": {
            "answer": "Это функционал, позволяющий вести диалог с&nbsp;клиентами в&nbsp;реальном времени через различные мессенджеры. Активные чаты включают все текущие чаты-&#65279;переписки (чаты в&nbsp;статусе &laquo;Ждут ответа&raquo; или &laquo;Отвечено&raquo;), т.е. только те&nbsp;чаты, в&nbsp;которых ведется активная переписка.",
            "title": "Что такое активный чат?"
          },
          "advertising": {
            "answer": "Реклама включает размещение рекламных материалов и&nbsp;баннеров, информирующих ваших клиентов о&nbsp;том, что вы&nbsp;используете сервис Donut. Это может быть отключено в&nbsp;определённых тарифах.",
            "title": "Что такое реклама сервиса Donut?"
          },
          "differences": {
            "answer": "Donut предлагает уникальный набор функций: создание сайта, который открывается в&nbsp;различных мессенджерах, одновременно поддерживая чат с&nbsp;клиентом с&nbsp;полноценным CRM для управления бизнесом. Платформа ориентирована на&nbsp;удобство и&nbsp;простоту использования, при этом покрывая необходимые требования бизнеса.",
            "title": "Чем Donut отличается от&nbsp;других сервисов?"
          },
          "enterprise": {
            "answer": "Подойдёт крупным компаниям и&nbsp;организациям, которым требуется максимальный функционал, расширенные возможности интеграции и&nbsp;персонализированная поддержка. Этот тариф предлагает высокую степень кастомизации и&nbsp;индивидуальный подход к&nbsp;каждому клиенту.",
            "title": "Кому подойдёт Enterprise тариф?"
          },
          "storage": {
            "answer": "Это облачное пространство, предназначенное для хранения данных вашего проекта, таких как файлы, изображения, документы и&nbsp;прочие материалы, необходимые для работы с&nbsp;сервисом.",
            "title": "Что такое хранилище?"
          },
          "support": {
            "answer": "Связаться с&nbsp;командой поддержки можно через электронную почту, чат на&nbsp;сайте, а&nbsp;также через мессенджеры, если это предусмотрено вашим тарифным планом. Для более оперативного решения вопросов рекомендуется использовать приоритетную поддержку или персонального менеджера.",
            "title": "Как связаться с&nbsp;командой поддержки?"
          },
          "supportTypes": {
            "answer": "<ul><li>Базовая: Предоставляет общую поддержку, включающую ответы на&nbsp;часто задаваемые вопросы и&nbsp;помощь по&nbsp;электронной почте.</li><li>Приоритетная: Обеспечивает более быстрый ответ на&nbsp;запросы и&nbsp;доступ к&nbsp;расширенной поддержке через удобные мессенджеры.</li><li>Персональный менеджер: Назначается персональный менеджер, который помогает с&nbsp;решением всех вопросов и&nbsp;координирует работу с&nbsp;сервисом.</li></ul>",
            "title": "Какие виды поддержек бывают?"
          }
        }
      },
      "sectionCards": {
        "createdInDonut": "Создано в Donut",
        "viewExample": "Посмотреть пример"
      },
      "validation": {
        "email": "Email должен содержать {'@'} и домен почтового клиента",
        "required": "Поле обязательное"
      }
    }
  },
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "ru",
    "name": "Russian",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
