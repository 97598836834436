export default defineNuxtRouteMiddleware(async (to) => {
  const BLOCKED_ROUTES = ["/client"];
  const AUTH_BLOCKED_ROUTE_NAMES = ["index", "features", "contacts", "questions", "rates"];
  const { createAppLink } = useAppLink();
  const { tokenState } = useAuthStore();
  const { getRefreshToken, removeRefreshToken } = useAuthToken();
  const { refreshToken } = getRefreshToken();

  if (
    BLOCKED_ROUTES.some((route) => to.path.startsWith(route)) &&
    (!refreshToken.value || !tokenState.value.accessToken)
  ) {
    const { hash, pathname, search } = useRequestURL();
    const redirectLink = createAppLink("/", "main");
    const redirectToLink = createAppLink(pathname + search + hash, "main");

    removeRefreshToken();

    return navigateTo(
      {
        path: redirectLink,
        query: {
          modal: SHARED_MODAL.AuthLogin,
          [SHARED_URL_QUERY_PARAMETERS.RedirectTo]: redirectToLink,
        },
      },
      {
        external: true,
      },
    );
  }

  if (
    AUTH_BLOCKED_ROUTE_NAMES.some((route) => String(to.name).startsWith(route)) &&
    refreshToken.value &&
    tokenState.value.accessToken
  ) {
    return navigateTo(
      {
        path: createAppLink("/client/projects", "main"),
        query: to.query,
      },
      {
        external: true,
      },
    );
  }
});
